import { Flex, Text } from '@raystack/apsara';

import styles from './EmptyState.module.css';

interface EmptyState {
  icon: React.ReactNode;
  headerText?: React.ReactNode;
  subHeaderText?: React.ReactNode;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
}

export const EmptyState = ({
  icon,
  headerText,
  subHeaderText,
  primaryButton,
  secondaryButton
}: EmptyState) => {
  return (
    <div className={styles.emptyState}>
      <div className={styles.iconContainer}>
        <div className={styles.icon}>{icon}</div>
      </div>

      <Flex direction='column' gap='small' align='center'>
        {headerText && (
          <Text size={5} weight={500} className={styles.headerText}>
            {headerText}
          </Text>
        )}

        {subHeaderText && (
          <Text size={4} className={styles.subHeaderText}>
            {subHeaderText}
          </Text>
        )}
      </Flex>

      {primaryButton}

      {secondaryButton}
    </div>
  );
};
