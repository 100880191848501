import { InputField, Text } from '@raystack/apsara';
import { Container } from '@raystack/frontier/react';

import { styled } from '@src/stitches.config';

export const Layout = styled('div', {
  minHeight: '100vh',
  width: '100%',
  background: 'var(--background-base)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});

export const Wrapper = styled('div', {
  position: 'relative',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: '240px',
  marginTop: '200px'
});

export const NewOrgFormContainer = styled(Container, {
  margin: 'auto',
  width: '100%',
  maxWidth: '386px',
  padding: 'var(--pd-32)',
  border: '1px solid var(--border-subtle)'
});

export const NewOrgFormField = styled(InputField, {
  alignItems: 'flex-start',
  position: 'relative',
  width: '100%'
});

export const SubHeading = styled(Text, {
  textAlign: 'center',
  color: 'var(--foreground-muted)'
});

export const FooterMsgText = styled(Text, {
  color: 'var(--foreground-subtle)',
  textAlign: 'center'
});
