/* eslint-disable */

/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios';
import axios from 'axios';

export type InferenceLabel = Record<string, string>;

export interface V1Alpha1Block {
  id?: string;
  name?: string;
  version: string;
}

export interface V1Alpha1BlockInfo {
  id?: string;
  name?: string;
  version?: string;
}

export interface V1Alpha1Component {
  block_id: string;
  block_info: V1Alpha1BlockInfo;
  depends_on: string[];
  inputs?: V1Alpha1Input[];
  /** TODO: add the unique check in the handler layer for components */
  name: string;
  properties?: V1Alpha1ComponentProperties;
}

export interface V1Alpha1ComponentPosition {
  x?: number;
  y?: number;
}

export interface V1Alpha1ComponentProperties {
  position?: V1Alpha1ComponentPosition;
}

export interface V1Alpha1CreateInferenceResponseBody {
  id?: string;
  message?: string;
}

export interface V1Alpha1DexterResponseAny {
  data?: any;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1DownloadJobResponse {
  data?: V1Alpha1DownloadJobResponse;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1DownloadTaskResponse {
  data?: V1Alpha1DownloadTaskResponse;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ReponsePublishedWorkflowsGet {
  data?: V1Alpha1PublishWorkflow[];
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseJobCreate {
  data?: V1Alpha1ResponseJobCreate;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseJobGet {
  data?: V1Alpha1ResponseJobGet;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseJobMetaList {
  data?: V1Alpha1ResponseJobMeta[];
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponsePublishWorkflow {
  data?: V1Alpha1ResponsePublishWorkflow;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseWorkflowCreate {
  data?: V1Alpha1ResponseWorkflowCreate;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseWorkflowGet {
  data?: V1Alpha1ResponseWorkflowGet;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseWorkflowMetaList {
  data?: V1Alpha1ResponseWorkflowMeta[];
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseWorkflowSoftDelete {
  data?: V1Alpha1ResponseWorkflowSoftDelete;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DexterResponseV1Alpha1ResponseWorkflowUpdate {
  data?: V1Alpha1ResponseWorkflowUpdate;
  error?: V1Alpha1Error;
}

export interface V1Alpha1DownloadInferenceResponse {
  id?: string;
  signed_url?: string;
  status?: string;
}

export interface V1Alpha1DownloadJobResponse {
  id?: string;
  signed_url?: string;
  status?: string;
  workflow_id?: string;
}

export interface V1Alpha1DownloadTaskResponse {
  id?: string;
  job_id?: string;
  signed_url?: string;
  status?: string;
  workflow_id?: string;
}

export interface V1Alpha1Error {
  code?: string;
  message?: string;
}

export interface V1Alpha1Inference {
  aoi_id: string;
  block: V1Alpha1Block;
  input: any[];
  metadata?: InferenceLabel;
  name?: string;
  project_id?: string;
}

export interface V1Alpha1InferenceEstimateCostResponse {
  estimate?: number;
}

export interface V1Alpha1InferenceListResponseBody {
  aoi_id?: string;
  cost?: number;
  created_at?: string;
  created_by?: string;
  err_msg?: string;
  estimated_time_in_min?: number;
  id?: string;
  input?: any[];
  metadata?: InferenceLabel;
  name?: string;
  output?: any[];
  progress?: number;
  status?: string;
}

export interface V1Alpha1InferenceResponseBody {
  block?: V1Alpha1Block;
  block_inf_end_time?: string;
  block_inf_start_time?: string;
  cost?: number;
  created_at?: string;
  end_time?: string;
  err_msg?: string;
  estimated_time_in_min?: number;
  input?: any[];
  metadata?: InferenceLabel;
  name?: string;
  output?: any[];
  progress?: number;
  start_time?: string;
  status?: string;
}

export interface V1Alpha1Input {
  from?: V1Alpha1NullableFromComponent;
  name: string;
  value?: V1Alpha1NullableValue;
}

export interface V1Alpha1NullableFromComponent {
  /** TODO: discuss with Pranjal to change the contract accordingly */
  component?: string;
  name?: string;
  valid?: boolean;
}

export interface V1Alpha1NullableValue {
  v?: string;
  valid?: boolean;
}

export interface V1Alpha1PublishWorkflow {
  published_at?: string;
  spec?: V1Alpha1Spec;
  version?: number;
  workflow_id?: string;
}

export interface V1Alpha1RequestAssetFileGet {
  url?: string;
}

export interface V1Alpha1RequestWorkflowCreate {
  description?: string;
  legacy_project_id?: string;
  name: string;
  project_id: string;
  /** TODO: check with Pranjal if we can get rid of this and have the component instead */
  spec: V1Alpha1Spec;
  user_id?: string;
}

export interface V1Alpha1RequestWorkflowPublish {
  spec?: V1Alpha1Spec;
}

export interface V1Alpha1RequestWorkflowUpdate {
  created_by?: string;
  description?: string;
  name?: string;
  project_id?: string;
  spec?: V1Alpha1Spec;
  user_id?: string;
}

export interface V1Alpha1ResponseJobCreate {
  cost?: number;
  created_at?: string;
  created_by?: string;
  estimated_duration?: number;
  exec_created_at?: string;
  exec_updated_at?: string;
  finished_at?: string;
  id?: string;
  progress?: string;
  spec?: V1Alpha1Spec;
  status?: string;
  tasks?: V1Alpha1ResponseTaskGet[];
  time_taken?: string;
  updated_at?: string;
  val_errors?: V1Alpha1ValError[];
  workflow_id?: string;
}

export interface V1Alpha1ResponseJobGet {
  cost?: number;
  created_at?: string;
  created_by?: string;
  estimated_duration?: number;
  exec_created_at?: string;
  exec_updated_at?: string;
  finished_at?: string;
  id?: string;
  progress?: string;
  spec?: V1Alpha1Spec;
  status?: string;
  tasks?: V1Alpha1ResponseTaskGet[];
  time_taken?: string;
  updated_at?: string;
  workflow_id?: string;
}

export interface V1Alpha1ResponseJobMeta {
  cost?: number;
  created_at?: string;
  created_by?: string;
  download_id?: string;
  estimated_duration?: number;
  finished_at?: string;
  id?: string;
  progress?: string;
  status?: string;
  time_taken?: string;
  updated_at?: string;
  workflow_id?: string;
}

export interface V1Alpha1ResponsePublishWorkflow {
  published_at?: string;
  spec?: V1Alpha1Spec;
  version?: number;
  workflow_id?: string;
}

export interface V1Alpha1ResponseTaskGet {
  block_inf_end_time?: string;
  block_inf_start_time?: string;
  /** Block info */
  block_info?: V1Alpha1BlockInfo;
  /** task created at */
  created_at?: string;
  depends_on?: string[];
  /** ErrMsg emitted by the model */
  err_msg?: string;
  /** Estimated time in seconds */
  estimated_time_duration?: number;
  /** FailureType indicates the type of failure the model encountered */
  failure_type?: string;
  /** task finished at */
  finished_at?: string;
  id?: string;
  info_logs?: number[];
  inputs?: object;
  name?: string;
  progress?: number;
  recv_time?: string;
  result?: object;
  send_time?: string;
  state_msg?: string;
  status?: string;
  /** total time taken by the task */
  time_taken?: string;
  /** task updated at */
  updated_at?: string;
}

export interface V1Alpha1ResponseWorkflowCreate {
  created_at?: string;
  created_by?: string;
  description?: string;
  id?: string;
  name?: string;
  project_id?: string;
  spec?: V1Alpha1Spec;
  updated_at?: string;
  val_errors?: V1Alpha1ValError[];
}

export interface V1Alpha1ResponseWorkflowGet {
  created_at?: string;
  created_by?: string;
  description?: string;
  estimated_cost?: number;
  id?: string;
  name?: string;
  project_id?: string;
  spec?: V1Alpha1Spec;
  updated_at?: string;
  val_errors?: V1Alpha1ValError[];
}

export interface V1Alpha1ResponseWorkflowMeta {
  created_at?: string;
  created_by?: string;
  description?: string;
  id?: string;
  legacy_project_id?: string;
  name?: string;
  project_id?: string;
  updated_at?: string;
}

export interface V1Alpha1ResponseWorkflowSoftDelete {
  deleted_at?: string;
  id?: string;
}

export interface V1Alpha1ResponseWorkflowUpdate {
  created_at?: string;
  created_by?: string;
  description?: string;
  estimated_cost?: number;
  id?: string;
  legacy_project_id?: string;
  name?: string;
  project_id?: string;
  spec?: V1Alpha1Spec;
  updated_at?: string;
  val_errors?: V1Alpha1ValError[];
}

export interface V1Alpha1Spec {
  components: V1Alpha1Component[];
}

export interface V1Alpha1UpdateInferenceRequest {
  name?: string;
}

export interface V1Alpha1ValError {
  component_name?: string;
  err?: string;
  from?: string;
  reason?: string;
  to?: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '//localhost:8000'
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title Dexter
 * @version 1.0.0
 * @baseUrl //localhost:8000
 * @externalDocs https://docs.google.com/document/d/15ETDT4nrAGY0PC5nhwxJUVmLJRDrPZZ0aqQPjPUGboM/edit#heading=h.5x3a12fiej1m
 * @contact MLOps team <mlops@pixxel.co.in>
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  v1Alpha1 = {
    /**
     * @description Returns the file contents of the given asset file
     *
     * @tags Assets
     * @name AssetCreate
     * @summary Get the contents of the given asset file
     * @request POST:/v1alpha1/asset/
     */
    assetCreate: (
      query: {
        /** projectId */
        projectId: string;
      },
      workflow: V1Alpha1RequestAssetFileGet,
      params: RequestParams = {}
    ) =>
      this.request<V1Alpha1DexterResponseAny, V1Alpha1DexterResponseAny>({
        path: `/v1alpha1/asset/`,
        method: 'POST',
        query: query,
        body: workflow,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the list of inferences details as JSON.
     *
     * @tags Inference
     * @name InferencesList
     * @summary List Inferences
     * @request GET:/v1alpha1/inferences/
     */
    inferencesList: (
      query: {
        /** aoi_id */
        aoi_id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1InferenceListResponseBody,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/inferences/`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the created inference details as JSON.
     *
     * @tags Inference
     * @name InferencesCreate
     * @summary Create an Inference
     * @request POST:/v1alpha1/inferences/
     */
    inferencesCreate: (
      inference: V1Alpha1Inference,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1CreateInferenceResponseBody,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/inferences/`,
        method: 'POST',
        body: inference,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with inference cost as JSON.
     *
     * @tags Inference
     * @name InferencesCostEstimateList
     * @summary Estimate inference cost
     * @request GET:/v1alpha1/inferences/cost/estimate
     */
    inferencesCostEstimateList: (
      body: V1Alpha1Inference,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1InferenceEstimateCostResponse,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/inferences/cost/estimate`,
        method: 'GET',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the inference details as JSON.
     *
     * @tags Inference
     * @name InferencesDetail
     * @summary Get an Inference
     * @request GET:/v1alpha1/inferences/{id}
     */
    inferencesDetail: (id: string, params: RequestParams = {}) =>
      this.request<V1Alpha1InferenceResponseBody, V1Alpha1DexterResponseAny>({
        path: `/v1alpha1/inferences/${id}`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with updated inference status as JSON.
     *
     * @tags Inference, Internal
     * @name InferencesCreate2
     * @summary Update Inference Status
     * @request POST:/v1alpha1/inferences/{id}
     * @originalName inferencesCreate
     * @duplicate
     */
    inferencesCreate2: (id: string, params: RequestParams = {}) =>
      this.request<void, V1Alpha1DexterResponseAny>({
        path: `/v1alpha1/inferences/${id}`,
        method: 'POST',
        ...params
      }),

    /**
     * @description Updates the internal inference entity. This cannot be used to update the inference status.
     *
     * @tags Inference
     * @name InferencesPartialUpdate
     * @summary Patches an inference entity.
     * @request PATCH:/v1alpha1/inferences/{id}
     */
    inferencesPartialUpdate: (
      id: string,
      UpdateInferenceRequest: V1Alpha1UpdateInferenceRequest,
      params: RequestParams = {}
    ) =>
      this.request<void, V1Alpha1DexterResponseAny>({
        path: `/v1alpha1/inferences/${id}`,
        method: 'PATCH',
        body: UpdateInferenceRequest,
        type: ContentType.Json,
        ...params
      }),

    /**
     * @description Responds with download id, status & signed url as JSON.
     *
     * @tags Inference
     * @name InferencesDownloadsDetail
     * @summary Create Download
     * @request GET:/v1alpha1/inferences/{id}/downloads
     */
    inferencesDownloadsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        V1Alpha1DownloadInferenceResponse,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/inferences/${id}/downloads`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the list of workflows for the user as JSON.
     *
     * @tags Workflow
     * @name WorkflowsList
     * @summary List Workflows for a given user
     * @request GET:/v1alpha1/workflows/
     */
    workflowsList: (
      query?: {
        /** Project Id */
        project_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseWorkflowMetaList,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the created workflow details as JSON.
     *
     * @tags Workflow
     * @name WorkflowsCreate
     * @summary Create a workflow
     * @request POST:/v1alpha1/workflows/
     */
    workflowsCreate: (
      workflow: V1Alpha1RequestWorkflowCreate,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseWorkflowCreate,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/`,
        method: 'POST',
        body: workflow,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the workflow details as JSON.
     *
     * @tags Workflow
     * @name WorkflowsDetail
     * @summary Return the workflow for the given id
     * @request GET:/v1alpha1/workflows/{workflowId}
     */
    workflowsDetail: (
      workflowId: string,
      query?: {
        /** Project Id */
        project_id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseWorkflowGet,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Deletes a workflow. Currently, workflows are only soft-deleted.
     *
     * @tags Workflow
     * @name WorkflowsDelete
     * @summary Delete a workflow
     * @request DELETE:/v1alpha1/workflows/{workflowId}
     */
    workflowsDelete: (workflowId: string, params: RequestParams = {}) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseWorkflowSoftDelete,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}`,
        method: 'DELETE',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the created workflow details as JSON.
     *
     * @tags Workflow
     * @name WorkflowsPartialUpdate
     * @summary Update workflow
     * @request PATCH:/v1alpha1/workflows/{workflowId}
     */
    workflowsPartialUpdate: (
      workflowId: string,
      workflow: V1Alpha1RequestWorkflowUpdate,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseWorkflowUpdate,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}`,
        method: 'PATCH',
        body: workflow,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the list of jobs for a given workflow.
     *
     * @tags Jobs
     * @name WorkflowsJobsDetail
     * @summary List jobs for a given workflow
     * @request GET:/v1alpha1/workflows/{workflowId}/jobs
     */
    workflowsJobsDetail: (workflowId: string, params: RequestParams = {}) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseJobMetaList,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/jobs`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the created job details as JSON.
     *
     * @tags Jobs
     * @name WorkflowsJobsCreate
     * @summary Create Job
     * @request POST:/v1alpha1/workflows/{workflowId}/jobs
     */
    workflowsJobsCreate: (
      workflowId: string,
      query: {
        /** Project Id */
        project_id: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseJobCreate,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/jobs`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the signed url to download the job asset.
     *
     * @tags Jobs
     * @name WorkflowsJobsDownloadsUpdate
     * @summary Download Job Asset
     * @request PUT:/v1alpha1/workflows/{workflowId}/jobs/{jobId}/downloads
     */
    workflowsJobsDownloadsUpdate: (
      workflowId: string,
      jobId: string,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1DownloadJobResponse,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/jobs/${jobId}/downloads`,
        method: 'PUT',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the signed url to download the task asset.
     *
     * @tags Tasks
     * @name WorkflowsJobsTasksDownloadsUpdate
     * @summary Download Task Asset
     * @request PUT:/v1alpha1/workflows/{workflowId}/jobs/{jobId}/tasks/{taskId}/downloads
     */
    workflowsJobsTasksDownloadsUpdate: (
      workflowId: string,
      jobId: string,
      taskId: string,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1DownloadTaskResponse,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/jobs/${jobId}/tasks/${taskId}/downloads`,
        method: 'PUT',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the created job details as JSON.
     *
     * @tags Jobs
     * @name WorkflowsJobsDetail2
     * @summary Get Job
     * @request GET:/v1alpha1/workflows/{workflowId}/jobs/{job_id}/
     * @originalName workflowsJobsDetail
     * @duplicate
     */
    workflowsJobsDetail2: (
      workflowId: string,
      jobId: string,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponseJobGet,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/jobs/${jobId}/`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the published workflow details as JSON.
     *
     * @tags Workflow
     * @name WorkflowsPublishDetail
     * @summary Returns all the published workflow given workflow id
     * @request GET:/v1alpha1/workflows/{workflowId}/publish
     */
    workflowsPublishDetail: (workflowId: string, params: RequestParams = {}) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ReponsePublishedWorkflowsGet,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/publish`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Responds with the published workflow details as JSON.
     *
     * @tags Workflow
     * @name WorkflowsPublishCreate
     * @summary Publish workflow
     * @request POST:/v1alpha1/workflows/{workflowId}/publish
     */
    workflowsPublishCreate: (
      workflowId: string,
      workflow: V1Alpha1RequestWorkflowPublish,
      params: RequestParams = {}
    ) =>
      this.request<
        V1Alpha1DexterResponseV1Alpha1ResponsePublishWorkflow,
        V1Alpha1DexterResponseAny
      >({
        path: `/v1alpha1/workflows/${workflowId}/publish`,
        method: 'POST',
        body: workflow,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  };
}
