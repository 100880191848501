import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  workflowList: [],
  activeWorkflow: {},
  selectedAois: [],
  estimated_cost: 0,
  activeJob: {},
  dataLoader: false,
  // this will store structured response on each autosave to create workflow done from frontend.
  validations: {},
  autoSaveLoader: false,
  editMode: false
};

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    // will update existing or add new aoi to list
    addSelectedAOI: (state, action) => {
      if (state.selectedAois.length > 0) {
        if (state.selectedAois.some(i => i.name === action.payload.name)) {
          let newList = state.selectedAois.filter(
            i => i.name !== action.payload.name
          );
          newList.push(action.payload);
          state.selectedAois = newList;
          return;
        }
        state.selectedAois.push(action.payload);
        return;
      }
      state.selectedAois.push(action.payload);
    },
    deleteSelectedAOI: (state, action) => {
      if (state.selectedAois.length > 0) {
        if (state.selectedAois.some(i => i.name === action.payload.name)) {
          let newList = state.selectedAois.filter(
            i => i.name !== action.payload.name
          );
          state.selectedAois = newList;
        }
      }
    },
    emptySelectedAOIS: (state, action) => {
      state.selectedAois = action.payload;
    },
    setEditMode: (state, action) => {
      state.editMode = action.payload;
    },
    updateValidations: (state, action) => {
      state.validations = action.payload;
    },
    setAutoSaveLoader: (state, action) => {
      state.autoSaveLoader = action.payload;
    },
    setWorkflowList: (state, action) => {
      state.workflowList = action.payload;
    },
    setActiveWorkflow: (state, action) => {
      state.activeWorkflow = action.payload;
    },

    updateEstimatedCost: (state, action) => {
      state.estimated_cost = action.payload;
    },
    updateTaskStatus: (state, action) => {
      state.activeJob.tasks = action.payload;
    },
    setActiveJob: (state, action) => {
      state.activeJob = action.payload;
    },

    setDataLoader: (state, action) => {
      state.dataLoader = action.payload;
    }
  }
});

export const {
  setActiveWorkflow,
  updateEstimatedCost,
  setWorkflowList,
  setActiveJob,
  updateTaskStatus,
  setDataLoader,
  updateValidations,
  setAutoSaveLoader,
  setEditMode,
  addSelectedAOI,
  deleteSelectedAOI,
  emptySelectedAOIS
} = workflowSlice.actions;

export default workflowSlice.reducer;
