import { createContext } from 'react';

import type { V1Beta1Organization, V1Beta1User } from '@raystack/frontier';

import type { OrganizationReadResponse } from '@src/clients/clerk/api';

interface IdentityContextType {
  activeOrg?: V1Beta1Organization;
  user?: V1Beta1User;
  organizations?: V1Beta1Organization[];
  activeOrgInfo?: OrganizationReadResponse;
}

export const IdentityContext = createContext<IdentityContextType>({
  activeOrg: {},
  user: {},
  organizations: [],
  activeOrgInfo: undefined
});
