import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_FILTER_VALUES } from '@src/utils/constants/EXPLORE_CONSTANTS';

const date = new Date();
date.setDate(date.getDate() - 15);

const initialState = {
  startDate: date,
  endDate: new Date(),
  areaCoverage: JSON.parse(localStorage.getItem('user'))?.filterValues
    ? JSON.parse(localStorage.getItem('user')).filterValues.areaCoverage
    : [...DEFAULT_FILTER_VALUES.area_coverage],
  cloudCoverage: JSON.parse(localStorage.getItem('user'))?.filterValues
    ? JSON.parse(localStorage.getItem('user')).filterValues.cloudCoverage
    : [...DEFAULT_FILTER_VALUES.cloud_coverage],
  spatialResolution: JSON.parse(localStorage.getItem('user'))?.filterValues
    ? JSON.parse(localStorage.getItem('user')).filterValues.spatialResolution
    : [...DEFAULT_FILTER_VALUES.spatial_gsd],
  offNadirAngle: JSON.parse(localStorage.getItem('user'))?.filterValues
    ? JSON.parse(localStorage.getItem('user')).filterValues.offNadirAngle
    : [...DEFAULT_FILTER_VALUES.off_nadir_angle],
  satellite: [
    {
      name: 'Sentinel-2',
      provider: 'planetary',
      satellite_id: 'Sentinel-2',
      collection_id: 'sentinel-2-l2a',
      queryables: ['eo:cloud_cover', 'gsd'],
      eo_bands: [
        'B01',
        'B02',
        'B03',
        'B04',
        'B05',
        'B06',
        'B07',
        'B08',
        'B09',
        'B11',
        'B12',
        'B8A',
        'SCL'
      ]
    }
  ],
  geometry: {},
  eoBands: [],
  // provider: "planetary",
  filterStates: {
    showAreaCoverage: JSON.parse(localStorage.getItem('user'))
      ?.filterPreferences
      ? JSON.parse(localStorage.getItem('user'))?.filterPreferences
          .showAreaCoverage
      : false,
    showCloudCoverage: JSON.parse(localStorage.getItem('user'))
      ?.filterPreferences
      ? JSON.parse(localStorage.getItem('user'))?.filterPreferences
          .showCloudCoverage
      : false,
    showGSD: JSON.parse(localStorage.getItem('user'))?.filterPreferences
      ? JSON.parse(localStorage.getItem('user'))?.filterPreferences.showGSD
      : false,
    showAngle: JSON.parse(localStorage.getItem('user'))?.filterPreferences
      ? JSON.parse(localStorage.getItem('user'))?.filterPreferences.showAngle
      : false
  }
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
    setSatellite(state, action) {
      state.satellite = action.payload;
    },
    setAreaCoverage(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterValues.areaCoverage = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.areaCoverage = action.payload;
    },
    setCloudCoverage(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterValues.cloudCoverage = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.cloudCoverage = action.payload;
    },
    setOffNadirAngle(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterValues.offNadirAngle = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.offNadirAngle = action.payload;
    },
    setSpatialResolution(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterValues.spatialResolution = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.spatialResolution = action.payload;
    },
    setGeometry(state, action) {
      state.geometry = action.payload;
    },
    resetGeometry(state) {
      state.geometry = {};
    },
    setEoBands(state, action) {
      state.eoBands = action.payload;
    },
    setShowAreaCoverage(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterPreferences.showAreaCoverage = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.filterStates.showAreaCoverage = action.payload;
    },
    setShowCloudCoverage(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterPreferences.showCloudCoverage = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.filterStates.showCloudCoverage = action.payload;
    },
    setShowGSD(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterPreferences.showGSD = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.filterStates.showGSD = action.payload;
    },
    setShowAngle(state, action) {
      let currentObj = JSON.parse(localStorage.getItem('user'));
      currentObj.filterPreferences.showAngle = action.payload;
      localStorage.setItem('user', JSON.stringify(currentObj));
      state.filterStates.showAngle = action.payload;
    },
    setProvider(state, action) {
      state.provider = action.payload;
    }
  }
});

export const {
  setStartDate,
  setEndDate,
  setSatellite,
  setAreaCoverage,
  setCloudCoverage,
  setSpatialResolution,
  setOffNadirAngle,
  setGeometry,
  resetGeometry,
  setEoBands,
  setShowAreaCoverage,
  setShowCloudCoverage,
  setShowGSD,
  setShowAngle,
  setProvider
} = filtersSlice.actions;

export default filtersSlice.reducer;
