import { createRoot } from 'react-dom/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';

import { FrontierProvider } from '@raystack/frontier/react';
import { AnalyticsProvider } from 'use-analytics';

import store from './redux/store';

import App from './App';
import ErrorBoundary from './components/Errors/ErrorBoundry';
import analyticsInstance from './config/analytics';
import { frontierConfig } from './config/frontier';
import { FlagsProvider } from './feature-flags/FlagsProvider';
import reportWebVitals from './reportWebVitals';

import './index.css';
import '@raystack/apsara/index.css';

import('@sentry/react').then(module => {
  module.init({
    dsn: 'https://235ac3569fd5416196f9a964d5668b1d@o4504519305658368.ingest.sentry.io/4504689617731584',
    integrations: [
      module.browserTracingIntegration(),
      module.breadcrumbsIntegration({
        console: false
      })
    ],
    ignoreErrors: [
      'TypeError: cancelled',
      'Non-Error promise rejection captured'
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: import.meta.env.VITE_APP_MODE || 'local',
    enabled: import.meta.env.VITE_APP_MODE !== 'local'
  });
});

if (import.meta.env.VITE_APP_MODE === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = createRoot(document.getElementById('root'));
root.render(
  <AnalyticsProvider instance={analyticsInstance}>
    <ErrorBoundary isRoot>
      <FrontierProvider config={frontierConfig}>
        <SkeletonTheme
          highlightColor='var(--background-base)'
          baseColor='var(--background-base-hover)'
        >
          <FlagsProvider>
            <Provider store={store}>
              <App className='bg-black' />
            </Provider>
          </FlagsProvider>
        </SkeletonTheme>
      </FrontierProvider>
    </ErrorBoundary>
  </AnalyticsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
