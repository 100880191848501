/* eslint-disable */

/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios';
import axios from 'axios';

/** AssistantStep */
export interface AssistantStep {
  /**
   * Role
   * @default "assistant"
   */
  role?: 'assistant';
  /** Content */
  content?: string | null;
  /** Tool Calls */
  tool_calls?: ToolCall[] | null;
}

/**
 * AvailableSatellites
 * Enum containing list of satellites supported by Seeker
 */
export enum AvailableSatellites {
  Sentinel2 = 'Sentinel-2',
  Landsat8And9 = 'Landsat-8 and 9',
  ModisNBARDaily = 'Modis NBAR Daily',
  PixxelD2 = 'Pixxel-D2',
  Shakuntala = 'Shakuntala',
  Sentinel1RTC = 'Sentinel-1 (RTC)',
  ModisLSTE8Day = 'Modis LSTE 8 Day',
  ModisSurfaceReflectance8Day = 'Modis Surface Reflectance 8-Day',
  EO01Hyperion = 'EO-01 Hyperion',
  Unsupported = 'Unsupported'
}

/** Chat */
export interface Chat {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /** User Id */
  user_id: string;
  /** Active */
  active: boolean;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /**
   * Is Locked
   * @default false
   */
  is_locked?: boolean;
}

/** ChatNotFound */
export interface ChatNotFound {
  /**
   * Code
   * @default "chat_not_found"
   */
  code?: 'chat_not_found';
  /** Message */
  message: string;
  /**
   * Details
   * @default {}
   */
  details?: object;
}

/**
 * Error
 * Contains information about issues with the user's prompt
 */
export interface Error {
  /**
   * Is Location Specified
   * True if prompt contains location info
   * @default false
   */
  is_location_specified?: boolean;
  /**
   * Is Time Specified
   * True if prompt contains time info
   * @default false
   */
  is_time_specified?: boolean;
  /**
   * Is Cloud Cover Specified
   * True if prompt contains cloud cover info
   * @default false
   */
  is_cloud_cover_specified?: boolean;
  /**
   * Is Satellite Specified
   * True if prompt contains satellite info
   * @default false
   */
  is_satellite_specified?: boolean;
  /**
   * Is Nsfw
   * Flag indicating whether the content is Not Safe For Work (NSFW).
   * @default false
   */
  is_nsfw?: boolean;
  /**
   * Is Search Intent
   * True if prompt can be used to search for satellite imagery
   * @default false
   */
  is_search_intent?: boolean;
  /**
   * Is Bbox Valid
   * True if bounding box area is less the threshold.
   * @default true
   */
  is_bbox_valid?: boolean;
}

/** ErrorResponse[ChatNotFound] */
export interface ErrorResponseChatNotFound {
  error: ChatNotFound;
}

/** ErrorResponse[MessageAndChatIDMismatch] */
export interface ErrorResponseMessageAndChatIDMismatch {
  error: MessageAndChatIDMismatch;
}

/** ErrorResponse[MessageGenerationInProgress] */
export interface ErrorResponseMessageGenerationInProgress {
  error: MessageGenerationInProgress;
}

/** ErrorResponse[MessageNotFound] */
export interface ErrorResponseMessageNotFound {
  error: MessageNotFound;
}

/** ErrorResponse[TooManyPromptsRequested] */
export interface ErrorResponseTooManyPromptsRequested {
  error: TooManyPromptsRequested;
}

/**
 * FilterState
 * Contains variables that can be used to execute a STAC search for satellite imagery
 */
export interface FilterState {
  /**
   * Satellites
   * List of one or more of ['Sentinel-2', 'Landsat-8 and 9', 'Modis NBAR Daily', 'Pixxel-D2', 'Shakuntala', 'Sentinel-1 (RTC)', 'Modis LSTE 8 Day', 'Modis Surface Reflectance 8-Day', 'EO-01 Hyperion', 'Unsupported'], if provided
   */
  satellites?: AvailableSatellites[] | null;
  /**
   * Bbox
   * bbox covering the requested AOI
   */
  bbox?: any[] | null;
  /**
   * Start Day
   * [DD: 1-31] Day of the date to search imagery from
   */
  start_day?: number | null;
  /**
   * Start Month
   * [MM: 1-12] Month of the date to search imagery from
   */
  start_month?: number | null;
  /**
   * Start Year
   * [YYYY] Year of the date to search imagery from
   */
  start_year?: number | null;
  /**
   * End Day
   * [DD: 1-31] Day of the date to search imagery up until
   */
  end_day?: number | null;
  /**
   * End Month
   * [MM: 1-12] Month of the date to search imagery up until
   */
  end_month?: number | null;
  /**
   * End Year
   * [YYYY] Year of the date to search imagery up until
   */
  end_year?: number | null;
  /**
   * Minimum Cloud Cover
   * [0-100] Minimum cloud cover
   */
  minimum_cloud_cover?: number | null;
  /**
   * Maximum Cloud Cover
   * [0-100] Maximum cloud cover
   */
  maximum_cloud_cover?: number | null;
}

/** Function */
export interface Function {
  /**
   * Arguments
   * @default ""
   */
  arguments?: string;
  /** Name */
  name: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Message */
export interface Message {
  /**
   * Id
   * @format uuid
   */
  id: string;
  /**
   * Chat Id
   * @format uuid
   */
  chat_id: string;
  role: RoleType;
  /** Content */
  content: string | null;
  /** Screen Context */
  screen_context: object | null;
  /** Is Complete */
  is_complete: boolean;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
}

/** MessageAndChatIDMismatch */
export interface MessageAndChatIDMismatch {
  /**
   * Code
   * @default "message_and_chat_id_mismatch"
   */
  code?: 'message_and_chat_id_mismatch';
  /** Message */
  message: string;
  /**
   * Details
   * @default {}
   */
  details?: object;
}

/** MessageGenerationInProgress */
export interface MessageGenerationInProgress {
  /**
   * Code
   * @default "message_generation_in_progress"
   */
  code?: 'message_generation_in_progress';
  /** Message */
  message: string;
  /**
   * Details
   * @default {}
   */
  details?: object;
}

/** MessageNotFound */
export interface MessageNotFound {
  /**
   * Code
   * @default "message_not_found"
   */
  code?: 'message_not_found';
  /** Message */
  message: string;
  /**
   * Details
   * @default {}
   */
  details?: object;
}

/**
 * OpenAIModels
 * Enum for OpenAI models
 */
export enum OpenAIModels {
  Gpt4 = 'gpt-4',
  Gpt40613 = 'gpt-4-0613',
  Gpt41106Preview = 'gpt-4-1106-preview',
  Gpt432K = 'gpt-4-32k',
  Gpt432K0613 = 'gpt-4-32k-0613',
  Gpt35Turbo = 'gpt-3.5-turbo',
  Gpt35Turbo1106 = 'gpt-3.5-turbo-1106'
}

/**
 * Prompt
 * Contains the user's prompt
 */
export interface Prompt {
  /**
   * Prompt
   * User's search query for geospatial data
   * @default ""
   */
  prompt?: string;
}

/** PromptsResponse */
export interface PromptsResponse {
  /** Sample Prompts */
  sample_prompts: string[];
}

/** RatingResponse */
export interface RatingResponse {
  rating: RatingType;
}

/** RatingType */
export enum RatingType {
  POSITIVE = 'POSITIVE',
  NEUTRAL = 'NEUTRAL',
  NEGATIVE = 'NEGATIVE'
}

/** ResponseData */
export interface ResponseDataInput {
  /** Inference Id */
  inference_id: string;
  filter_state: FilterState | null;
}

/** ResponseData */
export interface ResponseDataOutput {
  /** Inference Id */
  inference_id: string;
  filter_state: FilterState | null;
}

/** ResponseTimes */
export interface ResponseTimes {
  /**
   * Response Times
   * @default [0]
   */
  response_times?: number[];
  /**
   * Mean Response Time
   * @default 0
   */
  mean_response_time?: number;
  /**
   * Standard Deviation
   * @default 0
   */
  standard_deviation?: number;
}

/** RoleType */
export enum RoleType {
  User = 'user',
  Assistant = 'assistant',
  Tool = 'tool',
  System = 'system'
}

/**
 * ScreenContext
 * Provides a structure to the front-end to provide screen context to the model.
 * More keys for different screens will be added over time.
 */
export interface ScreenContext {
  /**
   * Values of filters currently set in the explore search UI
   * @default {}
   */
  filter_state?: FilterState;
}

/**
 * SearchInput
 * Container for the user's prompt, platform context, and inputs that have already been specified
 */
export interface SearchInput {
  /**
   * Contains the user's prompt
   * @default {"prompt":""}
   */
  prompt?: Prompt;
  /**
   * Current state of imagery search filters on the front-end
   * @default {}
   */
  filter_context?: FilterState;
  /**
   * Filter values that are specified already
   * @default {"is_cloud_cover_specified":false,"is_location_specified":false,"is_satellite_specified":false,"is_time_specified":false}
   */
  specified_inputs?: SpecifiedInputs;
}

/** SeekerResponse */
export interface SeekerResponseInput {
  data: ResponseDataInput;
  error: Error | null;
}

/** SeekerResponse */
export interface SeekerResponseOutput {
  data: ResponseDataOutput;
  error: Error | null;
}

/**
 * SpecifiedInputs
 * Used to specify what all parts of the search query are either inferred by the model or given by the user as manual
 * input already
 */
export interface SpecifiedInputs {
  /**
   * Is Location Specified
   * True if prompt contains location info
   * @default false
   */
  is_location_specified?: boolean;
  /**
   * Is Time Specified
   * True if prompt contains time info
   * @default false
   */
  is_time_specified?: boolean;
  /**
   * Is Cloud Cover Specified
   * True if prompt contains cloud cover info
   * @default false
   */
  is_cloud_cover_specified?: boolean;
  /**
   * Is Satellite Specified
   * True if prompt contains satellite info
   * @default false
   */
  is_satellite_specified?: boolean;
}

/** StepResponse */
export interface StepResponse {
  /**
   * Steps
   * List of system / user / assistant and tool steps.
   * @default []
   */
  steps?: StepWithID[];
  /**
   * Is Finished
   * Indicates whether more steps are being generated at the moment.
   * @default false
   */
  is_finished?: boolean;
}

/** StepWithID */
export interface StepWithID {
  /** Step */
  step: SystemStep | UserStep | AssistantStep | ToolCallStep;
  /**
   * Id
   * @format uuid
   */
  id: string;
}

/** SystemStep */
export interface SystemStep {
  /**
   * Role
   * @default "system"
   */
  role?: 'system';
  /**
   * Content
   * @default ""
   */
  content?: string;
}

/** TooManyPromptsRequested */
export interface TooManyPromptsRequested {
  /**
   * Code
   * @default "too_many_prompts_requested"
   */
  code?: 'too_many_prompts_requested';
  /** Message */
  message: string;
  /**
   * Details
   * @default {}
   */
  details?: object;
}

/** ToolCall */
export interface ToolCall {
  function: Function;
  /** Id */
  id: string;
  /**
   * Type
   * @default "function"
   */
  type?: 'function';
}

/** ToolCallStep */
export interface ToolCallStep {
  /**
   * Role
   * @default "tool"
   */
  role?: 'tool';
  /** Content */
  content?: string | null;
  /** Name */
  name: string;
  /** Tool Call Id */
  tool_call_id: string;
}

/** UserMessage */
export interface UserMessage {
  /** Message */
  message: string;
  /** @default {"filter_state":{}} */
  screen_context?: ScreenContext;
}

/** UserStep */
export interface UserStep {
  /**
   * Role
   * @default "user"
   */
  role?: 'user';
  /**
   * Content
   * @default ""
   */
  content?: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        );
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  metrics = {
    /**
     * @description Endpoint that serves Prometheus metrics.
     *
     * @name MetricsMetricsGet
     * @summary Metrics
     * @request GET:/metrics
     */
    metricsMetricsGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/metrics`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  ping = {
    /**
     * No description
     *
     * @name PingPingGet
     * @summary Ping
     * @request GET:/ping
     */
    pingPingGet: (params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/ping`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  chat = {
    /**
     * @description Creates a new `chat` for the given `user_id` and sets it as the active chat and sets any existing chats for the same user as `inactive`.
     *
     * @tags copilot
     * @name CreateChatChatPost
     * @summary Create Chat
     * @request POST:/chat
     */
    createChatChatPost: (params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/chat`,
        method: 'POST',
        format: 'json',
        ...params
      }),

    /**
     * @description Gets the current active chat for the given `user_id`.<br> Raises a `404` if no active chat is found, which will be the case only when there are no chats at all connected to the user.
     *
     * @tags copilot
     * @name GetChatChatGet
     * @summary Get Chat
     * @request GET:/chat
     */
    getChatChatGet: (params: RequestParams = {}) =>
      this.request<Chat, ErrorResponseChatNotFound | HTTPValidationError>({
        path: `/chat`,
        method: 'GET',
        format: 'json',
        ...params
      }),

    /**
     * @description Adds a new message to the given `chat_id`.<br> Raises a `404` if the chat is not found.<br> Raises a `409` if a response is already being generated.
     *
     * @tags copilot
     * @name AddMessageChatChatIdMessagesPost
     * @summary Add Message
     * @request POST:/chat/{chat_id}/messages
     */
    addMessageChatChatIdMessagesPost: (
      chatId: string,
      data: UserMessage,
      params: RequestParams = {}
    ) =>
      this.request<
        any,
        | ErrorResponseChatNotFound
        | ErrorResponseMessageGenerationInProgress
        | HTTPValidationError
      >({
        path: `/chat/${chatId}/messages`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags copilot
     * @name GetMessagesChatChatIdMessagesGet
     * @summary Get Messages
     * @request GET:/chat/{chat_id}/messages
     */
    getMessagesChatChatIdMessagesGet: (
      chatId: string,
      query?: {
        /** After Message Id */
        after_message_id?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        StepResponse,
        | (ErrorResponseChatNotFound | ErrorResponseMessageNotFound)
        | HTTPValidationError
      >({
        path: `/chat/${chatId}/messages`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Sets rating for the given `chat_id/message_id`.<br> Raises a `404` if the message is not found.<br>
     *
     * @tags copilot
     * @name GiveRatingChatChatIdMessagesMessageIdRatingPost
     * @summary Give Rating
     * @request POST:/chat/{chat_id}/messages/{message_id}/rating
     */
    giveRatingChatChatIdMessagesMessageIdRatingPost: (
      chatId: string,
      messageId: string,
      query: {
        rating: RatingType;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        any,
        | (
            | ErrorResponseChatNotFound
            | ErrorResponseMessageNotFound
            | ErrorResponseMessageAndChatIDMismatch
          )
        | ErrorResponseMessageGenerationInProgress
        | HTTPValidationError
      >({
        path: `/chat/${chatId}/messages/${messageId}/rating`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description Gets rating for the given `chat_id/message_id`.<br> Raises a `404` if the message is not found.<br>
     *
     * @tags copilot
     * @name GetRatingChatChatIdMessagesMessageIdRatingGet
     * @summary Get Rating
     * @request GET:/chat/{chat_id}/messages/{message_id}/rating
     */
    getRatingChatChatIdMessagesMessageIdRatingGet: (
      chatId: string,
      messageId: string,
      params: RequestParams = {}
    ) =>
      this.request<
        RatingResponse,
        | (
            | ErrorResponseChatNotFound
            | ErrorResponseMessageNotFound
            | ErrorResponseMessageAndChatIDMismatch
          )
        | ErrorResponseMessageGenerationInProgress
        | HTTPValidationError
      >({
        path: `/chat/${chatId}/messages/${messageId}/rating`,
        method: 'GET',
        format: 'json',
        ...params
      })
  };
  prompts = {
    /**
     * @description Returns N random sample prompts to show the user.
     *
     * @tags copilot
     * @name GetPromptsPromptsGet
     * @summary Get Prompts
     * @request GET:/prompts
     */
    getPromptsPromptsGet: (
      query?: {
        /**
         * N
         * @default 6
         */
        n?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        PromptsResponse,
        ErrorResponseTooManyPromptsRequested | HTTPValidationError
      >({
        path: `/prompts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      })
  };
  seeker = {
    /**
     * @description Main entry point into the Seeker app. Returns the results from user's inputs :param search_input: User input :return: Inference results
     *
     * @tags seeker
     * @name SearchSeekerSearchPost
     * @summary Search
     * @request POST:/seeker/search
     */
    searchSeekerSearchPost: (data: SearchInput, params: RequestParams = {}) =>
      this.request<
        SeekerResponseInput,
        SeekerResponseInput | HTTPValidationError
      >({
        path: `/seeker/search`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * @description Entrypoint which takes just text for convenience during development time :param user_prompt: user prompt :return: inference results
     *
     * @tags seeker
     * @name SearchTextSeekerInternalSearchTextPost
     * @summary Search Text
     * @request POST:/seeker/internal/search_text
     */
    searchTextSeekerInternalSearchTextPost: (
      query: {
        /** User Prompt */
        user_prompt: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        SeekerResponseInput,
        SeekerResponseInput | HTTPValidationError
      >({
        path: `/seeker/internal/search_text`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description this is an internal endpoint meant for demos only. It returns more human friendly outputs :param user_prompt: user prompt :return: inference results
     *
     * @tags seeker
     * @name SearchWithPrettyOutputSeekerInternalSearchWithPrettyOutputPost
     * @summary Search With Pretty Output
     * @request POST:/seeker/internal/search_with_pretty_output
     */
    searchWithPrettyOutputSeekerInternalSearchWithPrettyOutputPost: (
      query: {
        /** User Prompt */
        user_prompt: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/seeker/internal/search_with_pretty_output`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * @description This is an internal endpoint meant for profiling only. It runs the user_prompt `n` times through Seeker and allows to optionally override the backend (LLM) OpenAI model using `model` :param user_prompt: :param n: :param model: :return:
     *
     * @tags seeker
     * @name ProfileSeekerSeekerInternalProfilePost
     * @summary Profile Seeker
     * @request POST:/seeker/internal/profile
     */
    profileSeekerSeekerInternalProfilePost: (
      query: {
        /** User Prompt */
        user_prompt: string;
        /**
         * N
         * @default 1
         */
        n?: number;
        /** Model */
        model?: OpenAIModels | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResponseTimes, HTTPValidationError>({
        path: `/seeker/internal/profile`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags seeker
     * @name SubmitFeedbackSeekerSubmitFeedbackPost
     * @summary Submit Feedback
     * @request POST:/seeker/submit_feedback
     */
    submitFeedbackSeekerSubmitFeedbackPost: (
      query: {
        /** Inference Id */
        inference_id: string;
        /** Is Response Relevant */
        is_response_relevant: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/seeker/submit_feedback`,
        method: 'POST',
        query: query,
        format: 'json',
        ...params
      })
  };
}
