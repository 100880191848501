import type { FlagsValueType } from './FlagsProvider';

export const FLAGS = {
  NOTIFICATIONS: 'NOTIFICATIONS',
  NOTIFICATIONS_SETTING: 'NOTIFICATIONS_SETTING',
  STAC_TO_COG_IN_VISUALIZATION: 'STAC_TO_COG_IN_VISUALIZATION',
  ORDER_DESK: 'ORDER_DESK',
  CLIENT_TOKEN_CHECK: 'CLIENT_TOKEN_CHECK',
  CHANGE_DETECTION_V3: 'CHANGE_DETECTION_V3',
  COPILOT: 'COPILOT'
} as const;

export type FLAGS_KEYS = keyof typeof FLAGS;

export const FLAGS_LIST: {
  name: FLAGS_KEYS;
  defaultValue: FlagsValueType;
}[] = [
  { name: FLAGS.STAC_TO_COG_IN_VISUALIZATION, defaultValue: false },
  { name: FLAGS.NOTIFICATIONS, defaultValue: false },
  { name: FLAGS.NOTIFICATIONS_SETTING, defaultValue: false },
  { name: FLAGS.ORDER_DESK, defaultValue: false },
  { name: FLAGS.CLIENT_TOKEN_CHECK, defaultValue: false },
  { name: FLAGS.CHANGE_DETECTION_V3, defaultValue: false },
  { name: FLAGS.COPILOT, defaultValue: false }
];
