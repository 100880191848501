import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams
} from 'react-router';

import { ErrorState, Flex } from '@raystack/apsara';
import { V1Beta1Organization, V1Beta1User } from '@raystack/frontier';
import { useFrontier } from '@raystack/frontier/react';
import * as Sentry from '@sentry/browser';
import { validate as uuidValidate } from 'uuid';

import { apiClient } from '@src/clients';
import type { OrganizationReadResponse } from '@src/clients/clerk/api';
import AppLoader from '@src/components/AppLoader';
import { FLAGS, useFlags } from '@src/feature-flags';
import useProjects from '@src/hooks/useProjects';
import { setActiveUser } from '@src/redux/_reducers/authReducer';
import { customFetch } from '@src/utils/CustomFetch';
import { LAST_ORGANIZATION_KEY_NAME } from '@src/utils/constants/APP';
import { _initProjectLoad } from '@src/utils/helpers/ProjectHelpers';

import { IdentityContext } from './context';

export const IdentityContextProvider = () => {
  const { orgSlug } = useParams();
  const { isEnable } = useFlags();
  const location = useLocation();
  const navigate = useNavigate();
  const isOrderDeskEnable = isEnable(FLAGS.ORDER_DESK);

  const { client, setActiveOrganization } = useFrontier();
  const [organizations, setOrganizations] = useState<V1Beta1Organization[]>([]);
  const dispatch = useDispatch();
  const [currentOrgDetails, setCurrentOrgDetails] =
    useState<V1Beta1Organization>({});
  const [user, setUser] = useState<V1Beta1User>({});
  const [isOrgLoading, setIsOrgLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [errorCode, setErrorCode] = useState(0);
  const [activeOrgInfo, setActiveOrgInfo] = useState<{
    data: OrganizationReadResponse;
    isLoading: boolean;
    error: null | unknown;
  }>({ data: {}, isLoading: true, error: null });

  async function getActiveOrgInfo(id: string) {
    try {
      if (isOrderDeskEnable) {
        const { data } = await apiClient.clerkApi.v1OrganizationsDetail(id);
        setActiveOrgInfo({ data, isLoading: false, error: null });
      } else {
        setActiveOrgInfo(d => ({ ...d, isLoading: false }));
      }
    } catch (err) {
      setActiveOrgInfo(d => ({ ...d, isLoading: false, error: err }));
      console.error(err);
    }
  }

  const { getProjects } = useProjects({
    onSuccess: _initProjectLoad
  });

  const redirectToSlugUrl = (currentOrg: V1Beta1Organization) => {
    if (currentOrg?.id && currentOrg?.name) {
      const newPath = location.pathname.replace(currentOrg.id, currentOrg.name);
      navigate({ ...location, pathname: newPath }, { replace: true });
    }
  };

  const setActiveOrg = (currentOrg: V1Beta1Organization) => {
    if (currentOrg?.id && currentOrg?.name) {
      setActiveOrganization(currentOrg);
      setCurrentOrgDetails(currentOrg);
      getProjects(currentOrg.id);
      getActiveOrgInfo(currentOrg.id);
      customFetch.setOrg(currentOrg.id);
      localStorage.setItem(LAST_ORGANIZATION_KEY_NAME, currentOrg.name);
    }
  };

  const fetchUser = useCallback(async () => {
    try {
      setIsUserLoading(true);
      const resp = await client?.frontierServiceGetCurrentUser();
      const userData = resp?.data?.user;
      if (userData) {
        setUser(userData);
        dispatch(setActiveUser(userData));
      } else {
        throw new Error('Unable to get user');
      }
    } catch (err) {
      console.error(err);
      setErrorCode(401);
      navigate('/login');
    } finally {
      setIsUserLoading(false);
    }
  }, [client, dispatch, navigate]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    async function fetchAndSetActiveOrg(orgSlug: string) {
      try {
        setIsOrgLoading(true);
        const isSlugUUID = uuidValidate(orgSlug);
        const res =
          await client?.frontierServiceListOrganizationsByCurrentUser();
        const orgs = res?.data?.organizations || [];
        const currentOrg = orgs.find(org =>
          isSlugUUID ? org.id === orgSlug : org.name === orgSlug
        );
        setOrganizations(orgs);
        if (currentOrg?.id) {
          if (isSlugUUID) {
            redirectToSlugUrl(currentOrg);
          } else {
            setActiveOrg(currentOrg);
          }
        } else {
          setErrorCode(404);
        }
      } catch (err: unknown) {
        if (err instanceof Response) {
          setErrorCode(err?.status);
        }
        console.error(err);
      } finally {
        setIsOrgLoading(false);
      }
    }

    if (orgSlug && user?.id) {
      fetchAndSetActiveOrg(orgSlug);
    }
  }, [orgSlug, user?.id]);

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({
        id: user?.id,
        title: user?.title,
        email: user?.email
      });
    }
  }, [user]);

  const isLoading = isOrgLoading || isUserLoading || activeOrgInfo.isLoading;
  const orgNotFoundErrorMessage = `Organization ${orgSlug} not found`;

  return isLoading ? (
    <AppLoader />
  ) : errorCode === 404 ? (
    <Flex justify='center' align='center' style={{ height: '100vh' }}>
      <ErrorState status={orgNotFoundErrorMessage} />
    </Flex>
  ) : errorCode === 401 ? (
    <Navigate to={'/login'} replace />
  ) : errorCode !== 0 ? (
    <Flex justify='center' align='center' style={{ height: '100vh' }}>
      <ErrorState />
    </Flex>
  ) : currentOrgDetails?.id && user?.id ? (
    <IdentityContext.Provider
      value={{
        activeOrg: currentOrgDetails,
        user,
        organizations,
        activeOrgInfo: activeOrgInfo.data
      }}
    >
      <Outlet />
    </IdentityContext.Provider>
  ) : (
    <AppLoader />
  );
};
