export const TOOLMODE_EXPLORE = {
  SearchMode: 'search',
  ImageList: 'images',
  MapLayers: 'layers',
  MeasureArea: 'measure',
  Constellations: 'PixxelConstellations'
};

export const DEFAULT_FILTER_VALUES = {
  spatial_gsd: [0, 1000],
  area_coverage: [0, 100],
  cloud_coverage: [0, 100],
  off_nadir_angle: [0, 100]
};
