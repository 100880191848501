import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import * as _ from 'lodash';
import qs from 'query-string';

const storageKeyName = 'utmParams';

type UtmParams = Record<string, unknown>;

export function useSaveUtmParams() {
  const location = useLocation();
  const params = useMemo(() => qs.parse(location.search), [location.search]);
  useEffect(() => {
    const utmParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (key.toLowerCase().startsWith('utm_')) {
        acc[key] = String(value);
      }
      return acc;
    }, {} as UtmParams);

    if (!_.isEmpty(utmParams)) {
      sessionStorage.setItem(storageKeyName, JSON.stringify(utmParams));
    }
  }, [params]);
}

export function useReadUtmParams(): UtmParams {
  const data = sessionStorage.getItem(storageKeyName) || '{}';
  return JSON.parse(data);
}
