import { V1Beta1User } from '@raystack/frontier';
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_FILTER_VALUES } from '@src/utils/constants/EXPLORE_CONSTANTS';

export interface IAMDialogState {
  isOpen: boolean;
  route: string;
}

export interface InitialState {
  activeUser?: V1Beta1User;
  IAMDialogState: IAMDialogState;
}

const initialState: InitialState = {
  activeUser: {},
  IAMDialogState: {
    isOpen: false,
    route: '/'
  }
};

function initLocalStorageUser() {
  localStorage.setItem(
    'user',
    JSON.stringify({
      activeOrg: {},
      activeProject: {},
      activeAoi: {},
      activeWorkflow: {},
      activeJob: {},
      filterPreferences: {
        showAreaCoverage: false,
        showCloudCoverage: false,
        showGSD: false,
        showAngle: false
      },
      filterValues: {
        areaCoverage: [...DEFAULT_FILTER_VALUES.area_coverage],
        cloudCoverage: [...DEFAULT_FILTER_VALUES.cloud_coverage],
        spatialResolution: [...DEFAULT_FILTER_VALUES.spatial_gsd],
        offNadirAngle: [...DEFAULT_FILTER_VALUES.off_nadir_angle]
      },
      keyboardShortcuts: {
        // enableAoiList: true,
        // enableProjectList: true,
        // enableAoiDraw: true,
      },
      userDataPref: {
        spectralInfo: [],
        polls: []
      }
    })
  );
}

type Reducers = {
  logoutUser: CaseReducer<InitialState>;
  setActiveUser: CaseReducer<InitialState, PayloadAction<V1Beta1User>>;
  setIAMDialogState: CaseReducer<InitialState, PayloadAction<IAMDialogState>>;
};

export const authSlice = createSlice<InitialState, Reducers, 'auth'>({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: state => {
      localStorage.removeItem('user');
      localStorage.removeItem('searchHistory');
      state.activeUser = {};
    },
    setActiveUser: (state, action) => {
      state.activeUser = action.payload;
      initLocalStorageUser();
    },
    setIAMDialogState: (state, action) => {
      state.IAMDialogState = action.payload;
    }
  }
});

export const { logoutUser, setActiveUser, setIAMDialogState } =
  authSlice.actions;

export default authSlice.reducer;
