import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useFrontier } from '@raystack/frontier/react';

import {
  announcementMap,
  resetAnnouncement,
  setAnnouncement
} from '@src/redux/_reducers/appReducer';
import { setIAMDialogState } from '@src/redux/_reducers/authReducer';
import {
  OrganizationStateMap,
  SubscriptionStateMap
} from '@src/utils/constants/APP';

export default function useBilling() {
  const dispatch = useDispatch();

  const { client, activeSubscription, activeOrganization } = useFrontier();

  const fetchUserPermission = useCallback(
    async (orgId: string) => {
      try {
        const resp = await client?.frontierServiceCheckResourcePermission({
          resource: `app/organization:${orgId}`,
          permission: 'update'
        });
        const value = Boolean(resp?.data?.status);
        return value;
      } catch (err) {
        return false;
      }
    },
    [client]
  );

  useEffect(() => {
    async function checkSubscriptionState() {
      if (
        activeOrganization?.id &&
        activeOrganization?.state === OrganizationStateMap.ENABLED
      ) {
        if (activeSubscription?.state === SubscriptionStateMap.PAST_DUE) {
          const isAllowed = await fetchUserPermission(activeOrganization?.id);
          const action = isAllowed
            ? () =>
                dispatch(setIAMDialogState({ isOpen: true, route: '/billing' }))
            : null;
          dispatch(
            setAnnouncement({
              type: announcementMap.ERROR,
              message: 'Your Payment is due. Please try again',
              action: action,
              actionLabel: 'Retry'
            })
          );
        } else {
          dispatch(resetAnnouncement());
        }
      }
    }
    checkSubscriptionState();
  }, [
    activeOrganization?.id,
    activeOrganization?.state,
    activeSubscription?.state,
    dispatch,
    fetchUserPermission
  ]);

  return null;
}
