import { Flex, Text } from '@raystack/apsara';

import { DomainsFeedItem } from '@src/clients/beacon/api';

import { NotificationCategory, getNotificationDetails } from './events';

import styles from './styles.module.css';

export type FilterValues = 'All' | 'Unread' | NotificationCategory;

const categoryfilters: Array<{ label: FilterValues }> = [
  {
    label: 'AOI'
  },
  {
    label: 'Workflow'
  }
];

export function filterMessages(
  messages: DomainsFeedItem[],
  filterValue: FilterValues
) {
  switch (filterValue) {
    case 'All': {
      return messages;
    }
    case 'Unread': {
      return messages.filter(m => m.status === 'unread');
    }
    case 'AOI':
    case 'Workflow': {
      return messages.filter(m => {
        const { category } = getNotificationDetails(m.event || '');
        return category === filterValue;
      });
    }
    default:
      return [];
  }
}

interface FilterProps {
  unreadCount: number;
  value: FilterValues;
  setValue: (v: FilterValues) => void;
}

export default function Filter({ unreadCount, value, setValue }: FilterProps) {
  return (
    <Flex className={styles.filter}>
      <Flex
        className={styles.filterTag}
        data-selected={value === 'All'}
        onClick={() => setValue('All')}
        data-test-id='notification-filter-all-btn'
      >
        <Text weight={500} className={styles.filterTagLabel}>
          All
        </Text>
      </Flex>
      <Flex
        className={styles.filterTag}
        gap={'extra-small'}
        data-selected={value === 'Unread'}
        onClick={() => setValue('Unread')}
        data-test-id='notification-filter-unread-btn'
      >
        <Text weight={500} className={styles.filterTagLabel}>
          Unread
        </Text>
        {unreadCount ? (
          <Text style={{ color: 'var(--foreground-subtle)' }}>
            ({unreadCount})
          </Text>
        ) : null}
      </Flex>
      {categoryfilters.map(cf => {
        return (
          <Flex
            key={cf.label}
            className={styles.filterTag}
            data-selected={value === cf.label}
            onClick={() => setValue(cf.label)}
            data-test-id={`notification-filter-${cf.label}-btn`}
          >
            <Text weight={500} className={styles.filterTagLabel}>
              {cf.label}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
}
