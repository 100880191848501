import { Button } from '@raystack/apsara';
import axios from 'axios';
import * as _ from 'lodash';

import store from './redux/store';

import { DisplayMsg } from './components/PixxelUIKit/Toast';
import { setIAMDialogState } from './redux/_reducers/authReducer';
import { customFetch } from './utils/CustomFetch';
import { CURRENT_ORG_HEADER, ENTITLEMENT_MSGS } from './utils/constants/APP';
import { LogoutUser } from './utils/userHandler';

export async function addOrgIdRequestInterceptor(config) {
  const activeOrgId = customFetch.getOrg();
  config.headers[CURRENT_ORG_HEADER] = activeOrgId;
  return config;
}

export async function handleResponseStatusInterceptor(error) {
  if (error?.response?.status === 401) {
    await LogoutUser();
    return;
  }

  if (error?.response?.status === 403) {
    const message =
      error.response.data?.error?.message ||
      error.response.data?.detail ||
      error.response.data?.error;

    DisplayMsg('unauthorized-error', 'error', 'Unauthorized!', message);
    return Promise.reject(error);
  }

  if (error?.response?.status === 402) {
    const data = error?.response?.data;

    /**
     * @type {boolean} - if errorStructCheck returns false, then default fallback toast will show
     */
    const errorStructCheck = data && _.has(data, 'error');

    /**
     * @type {boolean}
     */
    const entitlement_failed =
      errorStructCheck &&
      data.error.code === ENTITLEMENT_MSGS.entitlementFailed;

    /**
     * @type {boolean}
     */
    const insufficent_credit =
      errorStructCheck &&
      data.error.code === ENTITLEMENT_MSGS.insufficientCredit;

    if (entitlement_failed) {
      DisplayMsg(
        ENTITLEMENT_MSGS.entitlementFailed,
        'error',
        'Feature unvailable!',
        'This feature is not available in your current plan. Please upgrade plan!',
        <Button
          data-test-id='btn-error-toast'
          variant='secondary'
          size='small'
          style={{ textWrap: 'nowrap' }}
          onClick={() =>
            store.dispatch(setIAMDialogState({ route: '/plans', isOpen: true }))
          }
        >
          Upgrade Plan
        </Button>
      );
      return Promise.reject(error);
    }

    if (insufficent_credit) {
      DisplayMsg(
        ENTITLEMENT_MSGS.insufficientCredit,
        'error',
        'Insufficient Credits!',
        "You don't have enough credits to use this feature. Please recharge tokens!"
      );
      return Promise.reject(error);
    }

    // when 402 does not return any of the above codes or error response does not
    // have the error key in its body
    DisplayMsg(
      'payment-error',
      'error',
      'Payment Error!',
      'We encountered some error related to payment. Kindly contact support!',
      <Button
        data-test-id='btn-error-toast'
        variant='secondary'
        size='small'
        style={{ textWrap: 'nowrap' }}
        onClick={() =>
          window.open(import.meta.env.VITE_CUSTMERSUPPORT_URL, '_blank')
        }
      >
        Support
      </Button>
    );
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

export const createPollInstance = baseURL => {
  const newInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  newInstance.interceptors.request.use(addOrgIdRequestInterceptor, error => {
    return Promise.reject(error);
  });
  newInstance.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 401) {
        await LogoutUser();
        return;
      }
      return Promise.reject(error);
    }
  );

  return newInstance;
};

export const createAxiosInstance = baseURL => {
  const newInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  newInstance.interceptors.request.use(addOrgIdRequestInterceptor, error => {
    return Promise.reject(error);
  });

  newInstance.interceptors.response.use(response => {
    // console.log("token already authenticated!");
    return response;
  }, handleResponseStatusInterceptor);

  return newInstance;
};

// For atlas related APIs
const dataStore = createAxiosInstance(import.meta.env.VITE_ATLAS);

const AtlasPoll = createPollInstance(import.meta.env.VITE_ATLAS);

const ArcPoll = createPollInstance(import.meta.env.VITE_VECTOR);

const DexterPoll = createPollInstance(import.meta.env.VITE_DEXTER);

// Vector aka Arc
const Arc = createAxiosInstance(import.meta.env.VITE_VECTOR);
const dexter = createAxiosInstance(import.meta.env.VITE_DEXTER);

// For image search
const imageSearch = createAxiosInstance(import.meta.env.VITE_IMG_SEARCH);
const Cosmos = createAxiosInstance(import.meta.env.VITE_COSMOS);
const Frontier = createAxiosInstance(import.meta.env.VITE_FRONTIER_URL);

// TiTiler
const CogTiTiler = createAxiosInstance(import.meta.env.VITE_COG_TILER);

export default {
  imageSearch,
  dataStore,
  dexter,
  Arc,
  Cosmos,
  Frontier,
  AtlasPoll,
  ArcPoll,
  DexterPoll,
  CogTiTiler
};
