import { useMemo } from 'react';
import { Navigate, Outlet, useMatch, useParams } from 'react-router';

import { useFrontier } from '@raystack/frontier/react';
import * as _ from 'lodash';
import useSWR from 'swr';

import PendingOrgPage from '@src/containers/Auth/PendingOrg';

import AppLoader from './AppLoader';

export const OnboardingRoute = () => {
  const { client } = useFrontier();
  const { orgId } = useParams('/organizations/:orgId');

  const isOrgListRoute = useMatch('/organizations');
  const isNewOrgRoute = useMatch('/organizations/new');

  const { data: userResp, isLoading: isUserRespLoading } = useSWR(
    '/user/self',
    () => client?.frontierServiceGetCurrentUser()
  );

  const user = userResp?.data?.user || {};

  const { data: orgResp, isLoading: isOrgLoading } = useSWR(
    orgId && !isOrgListRoute && !isNewOrgRoute
      ? `/organizations/${orgId}`
      : null,
    () => client.frontierServiceGetOrganization(orgId),
    {
      onError: console.error
    }
  );

  const org = orgResp?.data?.organization;

  const isLoading = isOrgLoading || isUserRespLoading;

  const isOrgEnabled = useMemo(() => {
    return org?.state === 'enabled';
  }, [org?.state]);

  return isLoading ? (
    <AppLoader />
  ) : _.isEmpty(user) ? (
    <Navigate replace to='/login' />
  ) : !isOrgEnabled && !_.isEmpty(org) ? (
    <PendingOrgPage organization={org} />
  ) : (
    <Outlet />
  );
};
