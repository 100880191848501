import * as _ from 'lodash';

export function getInitials(name, email) {
  return (
    _.flow(
      str => _.split(str, ' '),
      arr => _.map(arr, _.first),
      arr => _.take(arr, 2),
      arr => _.join(arr, '')
    )(name) || email?.charAt(0)
  );
}
