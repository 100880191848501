import { BellIcon } from '@radix-ui/react-icons';

import { ReactComponent as AoiIcon } from '@src/icons/aoi.svg';
import { ReactComponent as InsightsIcon } from '@src/icons/insights.svg';
import { ReactComponent as WorkflowIcon } from '@src/icons/workflow.svg';

export type NotificationCategory = 'AOI' | 'Workflow';

interface NotificationDetails {
  category: NotificationCategory | 'Unknown';
  icon: React.FunctionComponent<{ className?: string }>;
  status?: 'success' | 'failure';
}

type EventNotificationDetailsMap = Record<string, NotificationDetails>;

// copy the beacon events from https://github.com/pixxelhq/configs/blob/main/beacon/production.ctmpl
const eventNotificationDetailsMap: EventNotificationDetailsMap = {
  'aoi-creation-succeeded': {
    category: 'AOI',
    icon: AoiIcon,
    status: 'success'
  },
  'aoi-creation-failed': {
    category: 'AOI',
    icon: AoiIcon,
    status: 'failure'
  },
  'aoi-deleted': {
    category: 'AOI',
    icon: AoiIcon
  },
  'insight-run-succeeded': {
    category: 'AOI',
    icon: InsightsIcon,
    status: 'success'
  },
  'insight-run-failed': {
    category: 'AOI',
    icon: InsightsIcon,
    status: 'failure'
  },
  'workflow-deleted': {
    category: 'Workflow',
    icon: WorkflowIcon
  },
  'job-completed': {
    category: 'Workflow',
    icon: WorkflowIcon,
    status: 'success'
  },
  'job-failed': {
    category: 'Workflow',
    icon: WorkflowIcon,
    status: 'failure'
  },
  default: {
    category: 'Unknown',
    icon: BellIcon
  }
};

export function getNotificationDetails(event: string) {
  return (
    eventNotificationDetailsMap[event] || eventNotificationDetailsMap['default']
  );
}
